/* footer.css */
.footer {
  background-color: #383e48;
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer p {
  margin: 0;
}

.footer-links {
  margin-top: 10px;
  display: flex;
  gap: 15px;
}

.footer-links a {
  color: #61dafb;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
