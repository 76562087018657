/* start.css */
.start {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

h1 {
  margin-bottom: 40px;
  font-size: 24px;
}

.course-list {
  width: 100%;
  max-width: 800px;
}

.course-item-link {
  text-decoration: none;
  color: inherit;
}

.course-item {
  display: flex;
  align-items: center;
  background-color: #3b3f45;
  border: 1px solid #616161;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.course-item:hover {
  background-color: #616161;
  color: #ff9900;
}

.course-icon {
  flex: 0 0 40px;
  font-size: 24px;
  text-align: center;
}

.course-title {
  flex: 1;
  font-size: 18px;
  padding: 0 10px;
}

.course-arrow {
  flex: 0 0 40px;
  font-size: 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .course-item {
    flex-direction: column;
    text-align: left;
  }

  .course-icon,
  .course-arrow {
    text-align: left;
    margin-bottom: 5px;
  }

  .course-title {
    text-align: left;
    padding: 0;
  }
}
