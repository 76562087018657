.login-container {
    background-color: #282c34;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .login-form {
    background-color: #333;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  
  .login-form input {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 3px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #61dafb;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    color: #000;
    font-size: 16px;
  }
  
  .login-form button:hover {
    background-color: #21a1f1;
  }
  