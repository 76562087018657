/* Login.css */
.course-list {
    max-width: 600px;
    margin: 0 auto;
    padding: 1em;
    border-radius: 5px;
    background: #282c34;
  }
  
  .course-list h1 {
    text-align: center;
    margin-bottom: 1em;
  }
  
  .course-list ul {
    list-style: none;
    color: #282c34;
    padding: 0;
  }
  
  .course-list li {
    margin: 0.5em 0;
  }
  
  .course-link {
    text-decoration: none;
    color: #ffffff;
    font-size: 1em;
  }
  
  .course-link:hover {
    text-decoration: underline;
    color: #ffffff;
  }
  