/* Layout.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.layout {
  background-color: #282c34;
  color: white;
  min-height: 100vh; /* Ensures it takes the full height of the viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px; /* Adjust the padding */
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.top-bar {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Aligns the back button to the left */
  padding: 10px 0; /* Adjust the padding if necessary */
}

.back-button {
  padding: 5px 10px; /* Reduced padding for less space around the button */
  font-size: 1em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-button:hover {
  background-color: #0056b3;
}

.back-button svg {
  margin-right: 5px; /* Reduced space between text and icon */
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
