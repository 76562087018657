/* VideoBar.css */
.video-bar {
  text-align: center;
  margin: 20px 0;
  width: 100%; /* Ensures the bar takes full width */
  overflow-x: hidden; /* Prevents horizontal overflow */
}

.video-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: nowrap; /* Prevents wrapping */
  overflow-x: auto; /* Allows horizontal scrolling if needed */
  -ms-overflow-style: none;  /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

.video-row::-webkit-scrollbar {
  display: none;  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.video-item {
  flex: 0 0 auto;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Needed for lock overlay positioning */
}

.video-thumbnail {
  width: 150px; /* Fixed width */
  height: 200px; /* Fixed height */
  object-fit: cover; /* Ensures the aspect ratio is maintained and fills the box */
  border-radius: 5px;
  margin-bottom: 5px;
}

/* Hover Effect */
.video-item:hover .video-thumbnail {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-title {
  font-size: 14px;
  color: #fff;
  text-align: center;
  width: 150px; /* Match the width of the thumbnail for alignment */
}

/* Lock Overlay */
.video-lock-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.lock-icon {
  color: white;
  font-size: 24px;
}

/* Media Queries for Responsiveness */
@media (max-width: 599px) {
  .video-thumbnail {
    width: 100px; /* Smaller width for small screens */
    height: 130px; /* Smaller height for small screens */
  }

  .video-title {
    width: 100px; /* Match the width of the thumbnail for alignment */
  }
}

@media (min-width: 600px) and (max-width: 799px) {
  .video-thumbnail {
    width: 120px; /* Medium width for medium screens */
    height: 160px; /* Medium height for medium screens */
  }

  .video-title {
    width: 120px; /* Match the width of the thumbnail for alignment */
  }
}

@media (min-width: 800px) and (max-width: 1199px) {
  .video-thumbnail {
    width: 150px; /* Larger width for large screens */
    height: 200px; /* Larger height for large screens */
  }

  .video-title {
    width: 150px; /* Match the width of the thumbnail for alignment */
  }
}

@media (min-width: 1200px) {
  .video-thumbnail {
    width: 200px; /* Largest width for extra large screens */
    height: 250px; /* Largest height for extra large screens */
  }

  .video-title {
    width: 200px; /* Match the width of the thumbnail for alignment */
  }
}
