/* home.css */
.main {
  background-color: #282c34;
  color: white;
  min-height: 100vh; /* Ensures it takes the full height of the viewport */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.content {
  width: 100%;
  max-width: 800px;
  text-align: left; /* Ensure all content inside .content is left-aligned */
}

h1 {
  margin: 0 0 10px 0 !important;
  padding: 0;
  font-size: 50px !important;
}

p {
  margin: 0;
}

button.sign-up-button {
  background-color: #ff9900;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

button.sign-up-button:hover {
  background-color: #cc7a00;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main {
    padding: 1px;
  }

  h1 {
    font-size: 30px !important; /* Adjust font-size for smaller screens */
  }

  p {
    font-size: 14px;
  }

  button.sign-up-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
