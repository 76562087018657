/* courses.css */
.main {
    background-color: #282c34;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centers content vertically */
    padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden; /* Prevent horizontal overflow */
  }
  
  h1 {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 2em;
    width: 100%;
    max-width: 800px; /* Ensures the h1 elements don't stretch too wide */
    text-align: center; /* Center aligns the text */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .main {
      padding: 10px;
    }
  
    h1 {
      font-size: 18px;
    }
  }
  