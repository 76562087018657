.main {
    background-color: #282c34;
    color: white;
    min-height: 100vh; /* Ensures it takes the full height of the viewport */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Centers content vertically */
    padding: 20px;
    box-sizing: border-box;
    overflow-x: hidden; /* Prevents horizontal overflow */
    text-align: center; /* Centers the text */
  }
  
  h1 {
    margin: 0;
    padding: 10px 0;
    font-size: 2em; /* Adjust font size as needed */
  }
  
  p {
    margin: 0;
    padding: 10px 0;
    font-size: 1.2em; /* Adjust font size as needed */
    max-width: 800px; /* Limits the width of the paragraph */
    line-height: 1.5; /* Improves readability */
  }
  